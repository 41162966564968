import logo from './logo.svg';
import './App.css';
import MainPage from './MainPage';
import QuemSomos from './QuemSomos';
import Servicos from './Servicos';
import VaiACasa from './VaiACasa';
import Footer from './Footer';
import Navbar from './Navbar';
import Contacts from './Contacts';

function App() {
  return (
    <div className="App">
      <MainPage />

    </div>
  );
}

export default App;
