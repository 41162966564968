import React from 'react'

import farmacia from "./Assets/farmacia.jpg"

function VaiACasa() {
    return (
        <div className='w-full bg-lime-600'>
            <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:px-8 lg:py-24 relative">
                <div className="lg:absolute lg:inset-0">
                    <div className="lg:absolute lg:inset-y-0 lg:left-0 lg:w-1/2">
                        <img
                            className="h-56 w-full object-cover lg:absolute lg:h-full"
                            src={farmacia}
                            alt=""
                        />
                    </div>
                </div>
                <div className="relative px-4 pt-12 pb-16 sm:px-6 sm:pt-16 lg:mx-auto lg:grid lg:max-w-7xl lg:grid-cols-2 lg:px-8">
                    <div className="lg:col-start-2 lg:pl-8">
                        <div className="mx-auto max-w-prose text-base lg:ml-auto lg:mr-0 lg:max-w-lg">
                            <h3 className="mt-2 text-3xl font-bold leading-8 tracking-tight text-white sm:text-4xl">A FARMÁCIA</h3>
                            <p className="mt-8 text-lg text-gray-200 text-justify">
                                A Farmácia de Bodiosa é um estabelecimento de saúde situado na freguesia de Bodiosa. Fundada em 1986, serve esta freguesia e as freguesias limítrofes.
                            </p>
                            <div className="prose prose-indigo mt-5 text-black text-justify">
                                <p>
                                    Tem como missão um atendimento personalizado, dando resposta aos utentes em todas as vertentes da saúde, para o que conta com um leque de serviços especializados.
                                </p>
                                <br />
                                <p>
                                    Ao longo dos anos a farmácia modernizou-se, cresceu e aumentou a sua equipa, mantendo o seu foco no compromisso de servir a comunidade na área da saúde e bem estar.
                                </p>
                                <br />
                                <p className='text-center font-bold'>
                                    Farmácia de Bodiosa, sempre consigo!
                                </p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default VaiACasa