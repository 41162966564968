import React from 'react'

function Contacts() {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl py-8 px-4 sm:px-6 lg:py-12 lg:px-8">
                <div className="divide-y-2 divide-gray-200">
                    <div className="lg:grid lg:grid-cols-3 lg:gap-2">
                        <h2 className="text-2xl font-bold text-gray-900 sm:text-3xl sm:tracking-tight">Contactos</h2>
                        <div className="mt-8 grid grid-cols-1 gap-2 sm:grid-cols-2 sm:gap-x-8 sm:gap-y-4 lg:col-span-2 lg:mt-0">
                            <div>
                                <h3 className="text-lg font-medium leading-6 text-gray-900">Telefone</h3>
                                <dl className="mt-2 text-base text-gray-500">
                                    <div className="mt-1">
                                        <dd>+351 232 972 299</dd><dd className='text-sm'> (chamada para a rede fixa nacional)</dd>
                                    </div>
                                </dl>
                            </div>
                            <div>
                                <h3 className="text-lg font-medium leading-6 text-gray-900">Email</h3>
                                <dl className="mt-2 text-base text-gray-500">
                                    <div>
                                        <dd>fbodiosa@gmail.com</dd>
                                    </div>
                                </dl>
                            </div>

                        </div>
                    </div>
                    <div className="mt-16 pt-16 lg:grid lg:grid-cols-3 lg:gap-4">
                        <h2 className="text-2xl font-bold text-gray-900 sm:text-3xl sm:tracking-tight">Onde estamos</h2>
                        <div className="mt-8 grid grid-cols-1 gap-12 sm:grid-cols-2 sm:gap-x-4 sm:gap-y-12 lg:col-span-2 lg:mt-0">
                            <div>
                                <h3 className="text-lg font-medium leading-6 text-gray-900">Morada</h3>
                                <div className="mt-2 text-base text-gray-500">
                                    <p>Quinta do Cruzeiro, 3515-535, Viseu</p>
                                    <p className="mt-1 text-sm">40.7167° N, 7.9936° W</p>
                                </div>
                            </div>
                            <div>
                                <h3 className="text-lg font-medium leading-6 text-gray-900">Horário</h3>
                                <div className="mt-2 text-base text-gray-500">
                                    <p>Segunda a Sexta: 9H-20H</p>
                                    <p>Sábado: 9-13H</p>
                                    <dd className='text-xs'> Encerra nos feriados 1 janeiro, Domingo de Páscoa, 1 Maio, 24 e 25 Dezembro </dd>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default Contacts