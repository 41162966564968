import React from 'react'

import p1 from "./Assets/p1.JPG"
import p2 from "./Assets/p2.JPG"
import p3 from "./Assets/p3.JPG"
import p4 from "./Assets/p4.JPG"
import p5 from "./Assets/p5.JPG"
import p6 from "./Assets/p6.JPG"

const people = [
    {
        name: 'Drª Margarida Coelho',
        role: 'Directora Técnica/ Farmacêutica',
        imageUrl: p1
    },
    {
        name: 'Drª Cecília Borges',
        role: 'Farmacêutica',
        imageUrl: p2,
    },
    {
        name: 'Carla Fernandes',
        role: 'Técnica de Farmácia',
        imageUrl: p3,
    },
    {
        name: 'Sandra Martins',
        role: 'Técnica de Farmácia',
        imageUrl: p4,
    },
    {
        name: 'Drª Rita Ladeira',
        role: 'Farmacêutica',
        imageUrl: p5,
    },
    {
        name: 'Drª Stéphanie Pais',
        role: 'Farmacêutica',
        imageUrl: p6,
    },

]

const otherPeople = [
    {
        name: 'Inês Ferreira',
        role: 'Gestão Redes Sociais',
    },
    {
        name: 'Amália Campos',
        role: 'Técnica de Limpeza',
    }
]


function QuemSomos() {
    return (
        <div className="bg-lime-600">
            <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:px-8 lg:pt-24">
                <div className="grid grid-cols-1 gap-12 lg:grid-cols-3 lg:gap-8">
                    <div className="space-y-5 sm:space-y-4">
                        <h2 className="text-3xl text-white font-bold tracking-tight sm:text-4xl">A EQUIPA</h2>
                        <p className="text-xl mt-32 text-black">
                            A equipa da Farmácia de Bodiosa conta com seis pessoas, fique a conhecer cada uma delas!
                        </p>
                    </div>
                    <div className="lg:col-span-2">
                        <ul role="list" className="space-y-12 sm:grid sm:grid-cols-2 sm:gap-12 sm:space-y-0 lg:gap-x-8">
                            {people.map((person) => (
                                <li key={person.name}>
                                    <div className="text-left flex items-center space-x-4 lg:space-x-6">
                                        {person.imageUrl && <img className="h-16 w-16 rounded-full lg:h-20 lg:w-20" src={person.imageUrl} alt="" />}
                                        <div className="space-y-1 text-lg font-medium leading-6">
                                            <h3 className='text-white'>{person.name}</h3>
                                            <p className="text-green-900">{person.role}</p>
                                        </div>
                                    </div>
                                </li>
                            ))}
                        </ul>

                    </div>

                </div>
                <div className='flex justify-evenly w-full mt-24'>
                    {otherPeople.map((person) => (
                        <div key={person.name}>
                            <div className="text-left flex items-center space-x-4 lg:space-x-6">
                                {person.imageUrl && <img className="h-16 w-16 rounded-full lg:h-20 lg:w-20" src={person.imageUrl} alt="" />}
                                <div className=" text-sm font-sm leading-6">
                                    <h3 className='text-white'>{person.name}</h3>
                                    <p className="text-green-800">{person.role}</p>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default QuemSomos