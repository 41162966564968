import React from 'react'

import { Fragment } from 'react'
import { Popover, Transition } from '@headlessui/react'
import imagem from "./Assets/Equipa_Bodiosa.JPG"
import logocomnome from "./Assets/logocomnome.png"
import Navbar from './Navbar'
import {
    ArrowPathIcon,
    Bars3Icon,
    BookmarkSquareIcon,
    CalendarIcon,
    ChartBarIcon,
    CursorArrowRaysIcon,
    LifebuoyIcon,
    PhoneIcon,
    PlayIcon,
    ShieldCheckIcon,
    Squares2X2Icon,
    XMarkIcon,
} from '@heroicons/react/24/outline'
import { ChevronDownIcon } from '@heroicons/react/20/solid'
import QuemSomos from './QuemSomos'
import Servicos from './Servicos'
import VaiACasa from './VaiACasa'
import Contacts from './Contacts'
import Footer from './Footer'

const features = [
    {
        name: 'Analytics',
        href: '#',
        description: 'Get a better understanding of where your traffic is coming from.',
        icon: ChartBarIcon,
    },
    {
        name: 'Engagement',
        href: '#',
        description: 'Speak directly to your customers in a more meaningful way.',
        icon: CursorArrowRaysIcon,
    },
    { name: 'Security', href: '#', description: "Your customers' data will be safe and secure.", icon: ShieldCheckIcon },
    {
        name: 'Integrations',
        href: '#',
        description: "Connect with third-party tools that you're already using.",
        icon: Squares2X2Icon,
    },
    {
        name: 'Automations',
        href: '#',
        description: 'Build strategic funnels that will drive your customers to convert',
        icon: ArrowPathIcon,
    },
]
const callsToAction = [
    { name: 'Watch Demo', href: '#', icon: PlayIcon },
    { name: 'Contact Sales', href: '#', icon: PhoneIcon },
]
const resources = [
    {
        name: 'Help Center',
        description: 'Get all of your questions answered in our forums or contact support.',
        href: '#',
        icon: LifebuoyIcon,
    },
    {
        name: 'Guides',
        description: 'Learn how to maximize our platform to get the most out of it.',
        href: '#',
        icon: BookmarkSquareIcon,
    },
    {
        name: 'Events',
        description: 'See what meet-ups and other events we might be planning near you.',
        href: '#',
        icon: CalendarIcon,
    },
    { name: 'Security', description: 'Understand how we take your privacy seriously.', href: '#', icon: ShieldCheckIcon },
]
const recentPosts = [
    { id: 1, name: 'Boost your conversion rate', href: '#' },
    { id: 2, name: 'How to use search engine optimization to drive traffic to your site', href: '#' },
    { id: 3, name: 'Improve your customer experience', href: '#' },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
const navigation = [
    { name: 'Quem Somos', href: '#QuemSomos' },
    { name: 'Serviços', href: '#Servicos' },
    { name: 'Contactos', href: '#VaiACasa' },
    { name: 'A Farmácia vai a casa!', href: '#Contacts' },
]
function MainPage() {
    return (
        <div>
            <div className="relative bg-white " id="Top">
                <Popover className="relative bg-white shado ">
                    <div className="mx-auto max-w-7xl px-4 sm:px-6">
                        <div className="flex items-center justify-between py-6 md:justify-start md:space-x-10">
                            <div className="flex justify-start lg:w-0 lg:flex-1">
                                <a href="#">
                                    <span className="sr-only">Your Company</span>
                                    <img
                                        className="h-8 w-auto sm:h-16"
                                        src={logocomnome}
                                        alt=""
                                    />
                                </a>
                            </div>
                            <div className="-my-2 -mr-2 md:hidden">
                                <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                    <span className="sr-only">Open menu</span>
                                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                                </Popover.Button>
                            </div>
                            <Popover.Group as="nav" className="hidden space-x-10 md:flex">


                                <a href="#QuemSomos" className="text-lg font-medium text-gray-500 hover:text-gray-900">
                                    A Equipa

                                </a>
                                <a href="#Servicos" className="text-lg font-medium text-gray-500 hover:text-gray-900">
                                    Serviços

                                </a>
                                <a href="#Farmacia" className="text-lg font-medium text-gray-500 hover:text-gray-900">
                                    A Farmácia
                                </a>
                                <a href="#Contacts" className="text-lg font-medium text-gray-500 hover:text-gray-900">
                                    Contactos

                                </a>



                            </Popover.Group>
                            <div className="hidden items-center justify-end md:flex md:flex-1 lg:w-0">

                            </div>
                        </div>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="duration-200 ease-out"
                        enterFrom="opacity-0 scale-95"
                        enterTo="opacity-100 scale-100"
                        leave="duration-100 ease-in"
                        leaveFrom="opacity-100 scale-100"
                        leaveTo="opacity-0 scale-95"
                    >
                        <Popover.Panel
                            focus
                            className="absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition md:hidden"
                        >
                            <div className="divide-y-2 divide-gray-50 rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5">
                                <div className="px-5 pt-5 pb-6">
                                    <div className="flex items-center justify-between">
                                        <div>
                                            <img
                                                className="h-8 w-auto"
                                                src={logocomnome}
                                                alt="Your Company"
                                            />
                                        </div>
                                        <div className="-mr-2">
                                            <Popover.Button className="inline-flex items-center justify-center rounded-md bg-white p-2 text-gray-400 hover:bg-gray-100 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500">
                                                <span className="sr-only">Close menu</span>
                                                <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                                            </Popover.Button>
                                        </div>
                                    </div>
                                    <div className="mt-6">
                                        <nav className="grid gap-y-8">

                                        </nav>
                                    </div>
                                </div>
                                <div className="space-y-6 py-6 px-5">
                                    <div className="grid grid-cols-1 gap-y-4 gap-x-8">
                                        <a href="#QuemSomos" className="text-base font-medium text-gray-500 hover:text-gray-900">
                                            Quem Somos

                                        </a>
                                        <a href="#Servicos" className="text-base font-medium text-gray-500 hover:text-gray-900">
                                            Serviços

                                        </a>
                                        <a href="#Farmacia" className="text-base font-medium text-gray-500 hover:text-gray-900">
                                            Farmácia
                                        </a>
                                        <a href="#Contacts" className="text-base font-medium text-gray-500 hover:text-gray-900">
                                            Contactos
                                        </a>

                                    </div>
                                </div>
                            </div>
                        </Popover.Panel>
                    </Transition>
                </Popover>

                <main className="lg:relative">
                    <div className="mx-auto w-full max-w-7xl pt-16 pb-20 text-center lg:py-48 lg:text-left">
                        <div className="px-4 sm:px-8 lg:w-1/2 xl:pr-16">
                            <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl md:text-6xl lg:text-5xl xl:text-6xl">
                                <span className="block xl:inline">Farmácia de Bodiosa</span>{' '}
                                <span className="block text-lime-500 xl:inline">Sempre consigo!</span>
                            </h1>

                        </div>
                    </div>
                    <div className="relative h-64 w-full sm:h-72 md:h-96 lg:absolute lg:inset-y-0 lg:right-0 lg:h-full lg:w-1/2">
                        <img
                            className="absolute inset-0 h-full w-full object-cover"
                            src={imagem}
                            alt=""
                        />
                    </div>
                </main>
            </div>
            <div id="QuemSomos"><QuemSomos /></div>
            <div id="Servicos"><Servicos /></div>
            <div id="Farmacia"><VaiACasa /></div>
            <div id="Contacts"><Contacts /></div>
            <div id="Footer"><Footer /></div>
        </div>

    )
}

export default MainPage