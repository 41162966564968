import React from 'react'
import s2 from "./Assets/s2.png"
import s1 from "./Assets/s1.png"
import s3 from "./Assets/s3.png"
import s4 from "./Assets/s4.png"
import s5 from "./Assets/s5.png"
import s6 from "./Assets/s6.png"
import s7 from "./Assets/s7.png"
import s8 from "./Assets/s8.png"
import s9 from "./Assets/s9.png"
import s10 from "./Assets/s10.png"
import s12 from "./Assets/s12.png"
import s11 from "./Assets/s11.png"

const people = [
    {
        name: 'Nutrição',
        role: '30 min',
        imageUrl: s2,
        bio: 'Planos de alimentação ajustados às suas necessidades. Perda de peso.',
    },
    {
        name: 'Tratamento de Feridas',
        role: '15 min',
        imageUrl: s1,
        bio: '',
    },
    {
        name: 'Serviço Pé Diabético // Podologia',
        role: '30 min',
        imageUrl: s12,
        bio: '',
    },
    {
        name: 'Perfuração de Orelhas',
        role: '15 min',
        imageUrl: s11,
        bio: '',
    },
    {
        name: 'Mediçao Pressão Arterial',
        role: '15 min',
        imageUrl: s3,
        bio: '- Normal - AOBP : Medição automatizada da PA sem assistência',
    },
    {
        name: 'Determinação IMC',
        role: '15 min',
        imageUrl: s10,
        bio: '',
    },
    {
        name: 'Preparação individualizada de Medicação',
        role: '30 min',
        imageUrl: s8,
        bio: '',
    },
    {
        name: 'Testes Rápidos de COVID-19',
        role: '15 min',
        imageUrl: s7,
        bio: '',
    },
    {
        name: 'Administração de Injectáveis',
        role: '15 min',
        imageUrl: s5,
        bio: '',
    },
    {
        name: 'Testes Rápidos de COVID-19',
        role: '15 min',
        imageUrl: s6,
        bio: 'Administração de Injectáveis e vacinas não incluídas no Plano Nacional de Vacinação (PNV).',
    },
    {
        name: 'MAPA - 48 horas',
        role: '48 horas',
        imageUrl: s9,
        bio: 'Monitorização ambulatória da pressão arterial de 48 horas.',
    },
    {
        name: 'Determinação de Parâmetros Bioquímicos',
        role: '15 min',
        imageUrl: s4,
        bio: '- Glicémia - Colesterol - Triglicéridos',
    },

    // More people...
]

function Servicos() {
    return (
        <div className="bg-white">
            <div className="mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:px-8 lg:py-24">
                <div className="space-y-12">
                    <h2 className="text-3xl font-bold tracking-tight sm:text-4xl">Serviços</h2>

                    <ul
                        role="list"
                        className="space-y-6 lg:grid lg:grid-cols-2 lg:items-start lg:gap-x-8 lg:gap-y-12 lg:space-y-0"
                    >
                        {people.map((person) => (
                            <li key={person.name}>
                                <div className="space-y-1 sm:grid sm:grid-cols-3 sm:gap-6 sm:space-y-0 lg:gap-8">
                                    <div className='p-16 sm:p-0'>
                                        <div className="aspect-w-3 aspect-h-3 h-0 sm:aspect-w-3 sm:aspect-h-3">
                                            <img className="object-contain shadow-lg p-14" src={person.imageUrl} alt="" />
                                        </div>
                                    </div>
                                    <div className="sm:col-span-2">
                                        <div className="space-y-1">
                                            <div className="space-y-1 text-lg font-medium leading-6">
                                                <h3>{person.name}</h3>
                                                <p className="text-indigo-600">{person.role}</p>
                                            </div>
                                            <div className="text-lg">
                                                <p className="text-gray-500">{person.bio}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default Servicos